/** @jsxImportSource @emotion/react */
import * as React from 'react';
import {createTheme, styled, ThemeProvider} from '@mui/material/styles';
import CssBaseline from '@mui/material/CssBaseline';
import MuiDrawer from '@mui/material/Drawer';
import Box from '@mui/material/Box';
import MuiAppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import List from '@mui/material/List';
import Typography from '@mui/material/Typography';
import Divider from '@mui/material/Divider';
import IconButton from '@mui/material/IconButton';
import MenuIcon from '@mui/icons-material/Menu';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import {MainListItems, SecondaryListItems, TertiaryListItems} from './listItems';
import LogoutIcon from '@mui/icons-material/Logout';
import {Button} from "@mui/material";
import {Route, Routes, useNavigate} from "react-router-dom";
import SRSForwardGraphsPage from "./flight_data/SRSForwardGraphs/SRSForwardGraphsPage";
import GitlabPlanningPage from "./gitlab/categories/GitlabPlanningPage";
import CiriumFlightsPage from "./flight_data/CiriumFlights/CiriumFlightsPage";
import UBSPage from "./flight_data/UBS/UBSPage";
import FlightRadarPage from "./flight_data/FlightRadar/FlightRadarPage";
import TSAPage from "./flight_data/TSAPage/TSAPage";
import ASMPage from "./flight_data/ASM/ASMPage";
import SRSRoutesPage from "./flight_data/SRSRoutes/SRSRoutesPage";
import ExportsPage from "./exports/Exports";
import BillSemrushPage from "./billdotocom/semrush/BillSemrush";
import DivvyPage from "./billdotocom/Divvy";
import ProductivitySemrushPage from "./productivity/semrush/SemrushPage";
import GitlabSales from "./gitlab/sales_team/GitlabSales";
import AmznCESalesPage from "./amzn/ce_sales/CESales";
import BuyWithPrimePage from "./amzn/shopify/BuyWithPrime";
import BuiltwithPage from "./productivity/builtwith/BuiltwithPage";
import TwitterPage from "./productivity/twitter/TwitterPage";
import ExperimentsPage from "./experiments/ExperimentsPage";
import DatadogWorkforcePage from "./datadog/workforce/DatadogWorkforcePage";
import RegionalFlightsPage from "./flight_data/CiriumFlights/RegionalFlightsPage";
import FlightDataOrdersPage from "./flight_data/EventsDashboard/FlightDataOrdersPage";
import FlightDataFirstFlightsPage from "./flight_data/EventsDashboard/FlightDataFirstFlightPage";
import FleetAgePage from "./flight_data/FleetAge/FleetAgePage";
import ZoominfoWorkforcePage from "./zoominfo/workforce/ZoominfoWorkforcePage";
import DatadogHGInsightsPage from "./datadog/workforce/DatadogHGInsightsPage";
import ProcoreAppAnniePage from "./procore/AppAnnie";
import ProcoreWorkforcePage from "./procore/Workforce";
import ProductivityHGInsightsPage from "./productivity/ProductivityHGInsightsPage";
import {ReactComponent as ObernLogo} from '../components/logos/oberndev.svg';
import GuidewireWorkforcePage from "./guidewire/GuidewireWorkforcePage";
import ProductivityWorkforcePage from "./productivity/ProductivityWorkforcePage";
import ETFEqualWeightsPage from "./etfs/equal_weights/ETF - equal weights";
import ETFCAGRsPage from "./etfs/ETF - CAGRs";
import ProductivityG2Page from "./productivity/g2/G2Page";
import BuyerIntentPage from "./productivity/g2/BuyerIntentPage";
import IBKRAppAnnie from "./ibkr/IBKRAppAnnie";
import IBKRSemrush from "./ibkr/IBKRSemrush";
import ProductivityAppAnnieSummary from "./productivity/ProductivityAppAnnieSummary";
import IBKRAppAnnieSummary from "./ibkr/IBKRAppAnnieSummary";
import GithubStars from "./snowflake/GithubStars";
import DBEngines from "./snowflake/DBEngines";
import TextCruncherEvaluation from "./textCruncher/TextCruncherEvaluation";
import DatadogBuyerIntentPage from "./datadog/DatadogBuyerIntentPage";
import SFOfficesTable from "./loopnet/SFOfficesPage";
import SnowflakeHGInsightsPage from "./snowflake/SnowflakeHGInsightsPage";
import EtfSpy from "./etfs/spy/EtfSpy";
import MondayIndeed from "./productivity/MondayIndeed";
import SnowIndeed from "./snowflake/SnowIndeed";
import OtherIndeed from "./other/OtherIndeed";
import MondayAcuityLinkedin from "./productivity/MondayAcuityLinkedin";
import OtherAcuityLinkedin from "./other/OtherAcuityLinkedin";
import MongoDBEngines from "./mongodb/MongoDBEngines";
import MongoGithubStars from "./mongodb/MongoGithubStars";
import MongoIndeed from "./mongodb/MongoIndeed";
import ShopifyBuiltwithPage from "./shopify/ShopifyBuiltWithPage";
import MongoGoogleTrends from "./mongodb/MongoGoogleTrends";
import StackOverflowSurveyComponent from "./mongodb/MongoStackOverflow";
import MondayGoogleTrends from "./productivity/MondayGoogleTrends";
import DatadogCncfGithub from "./datadog/DatadogCncfGithub";
import DatadogOpenTelemetryGithub from "./datadog/DatadogOpenTelemetryGithub";
import DexcomAppAnnie from "./dexcom/DexcomAppAnnie";
import TeslaAppAnnie from "./tesla/TeslaAppAnnie";
import LLMsSemrush from "./llms/LLMsSemrush";
import LLMsAppAnnie from "./llms/LLMsAppAnnie";
import CPNGSemrush from "./cpng/CPNGSemrush";
import CpngAppAnnie from "./cpng/CpngAppAnnie";
import ShopifyAppAnnie from "./shopify/ShopifyAppAnnie";
import AutodeskBuyerIntentPage from "./autodesk/AutodeskBuyerIntentPage";
import AutodeskG2Page from "./autodesk/AutodeskG2Page";
import AutodeskSemrush from "./autodesk/AutodeskSemrush";
import CECompetitors from "./amzn/ce_sales/CECompetitors";
import DatadogSemrush from "./datadog/DatadogSemrush";
import DexcomSteloAppAnnie from "./dexcom/DexcomSteloAppAnnie";
import CostarSemrush from "./costar/CostarSemrush";
import FolioSemrush from "./folio/FolioSemrush";
import FolioGuardSemrush from "./folio/FolioGuardSemrush";
import FolioBuiltWith from "./folio/FolioBuiltWith";

const drawerWidth = 240;

const AppBar = styled(MuiAppBar, {
    shouldForwardProp: (prop) => prop !== 'open',
})(({theme, open}) => ({
    zIndex: theme.zIndex.drawer + 1,
    transition: theme.transitions.create(['width', 'margin'], {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
    }),
    ...(open && {
        marginLeft: drawerWidth,
        width: `calc(100% - ${drawerWidth}px)`,
        transition: theme.transitions.create(['width', 'margin'], {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.enteringScreen,
        }),
    }),
}));

const Drawer = styled(MuiDrawer, {shouldForwardProp: (prop) => prop !== 'open'})(
    ({theme, open}) => ({
        '& .MuiDrawer-paper': {
            position: 'relative',
            whiteSpace: 'nowrap',
            width: drawerWidth,
            transition: theme.transitions.create('width', {
                easing: theme.transitions.easing.sharp,
                duration: theme.transitions.duration.enteringScreen,
            }),
            boxSizing: 'border-box',
            ...(!open && {
                overflowX: 'hidden',
                transition: theme.transitions.create('width', {
                    easing: theme.transitions.easing.sharp,
                    duration: theme.transitions.duration.leavingScreen,
                }),
                width: theme.spacing(7),
                [theme.breakpoints.up('sm')]: {
                    width: theme.spacing(9),
                },
            }),
        },
    }),
);

const mdTheme = createTheme({
        palette: {
            primary: {
                main: '#4d5656', // Replace with your desired color
            },
        }
    }
)

function DashboardContent({handleLogout}) {
    const [open, setOpen] = React.useState(true);
    const toggleDrawer = () => {
        setOpen(!open);
    };

    const navigate = useNavigate();

    return (
        <ThemeProvider theme={mdTheme}>
            <Box sx={{display: 'flex'}}>
                <CssBaseline/>

                {open ? <Drawer variant="permanent" open={true}
                                sx={{
                                    boxSizing: 'content-box',
                                    height: '100vh',
                                }}>
                    <Toolbar
                        sx={{
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'flex-end',
                            px: [1],
                        }}
                    >
                        <IconButton onClick={toggleDrawer}>
                            <ChevronLeftIcon/>
                        </IconButton>
                    </Toolbar>
                    <Divider/>
                    <List component="nav">
                        <MainListItems navigate={(navigate)}/>
                        <Divider sx={{my: 1}}/>
                        <SecondaryListItems navigate={navigate}/>
                        <Divider sx={{my: 1}}/>
                        <TertiaryListItems navigate={navigate}/>
                        {/*<Divider sx={{my: 1}}/>*/}
                        {/*<OtherListItems navigate={navigate}/>*/}

                    </List>
                </Drawer> : null}
                <Box
                    component="main"
                    sx={{
                        backgroundColor: (theme) =>
                            theme.palette.mode === 'light'
                                ? theme.palette.grey[100]
                                : theme.palette.grey[900],
                        flexGrow: 1,
                        height: '100vh',
                        overflow: 'auto',
                    }}
                >
                    <AppBar position="absolute" open={open}>
                        <Toolbar
                            sx={{
                                pr: '24px', // keep right padding when drawer closed
                            }}
                        >
                            <IconButton
                                edge="start"
                                color="inherit"
                                aria-label="open drawer"
                                onClick={toggleDrawer}
                                sx={{
                                    marginRight: '36px',
                                    ...(open && {display: 'none'}),
                                }}
                            >
                                <MenuIcon/>
                            </IconButton>
                            {/*<div style={{display: 'flex', alignItems: 'center'}}>*/}
                            <ObernLogo style={{width: 32, height: 32, marginRight: 15}}/>
                            <Typography
                                component="h1"
                                variant="h6"
                                color="inherit"
                                noWrap
                                sx={{flexGrow: 1}}
                            >
                                obern.dev dashboard
                            </Typography>
                            {/*</div>*/}
                            <Button
                                variant="outlined"
                                color="inherit"
                                endIcon={<LogoutIcon/>}
                                onClick={handleLogout}
                            >
                                Logout
                            </Button>
                        </Toolbar>
                    </AppBar>
                    <Toolbar/>
                    <Routes>
                        <Route path='experiment' element={<ExperimentsPage/>}/>
                        <Route path='flight_data/cirium_flights' element={<CiriumFlightsPage/>}/>
                        <Route path='flight_data/regional_flights' element={<RegionalFlightsPage/>}/>
                        <Route path='flight_data/ubs' element={<UBSPage/>}/>
                        <Route path='flight_data/asm' element={<ASMPage/>}/>
                        <Route path='flight_data/srs_routes' element={<SRSRoutesPage/>}/>
                        <Route path='flight_data/flight_radar' element={<FlightRadarPage/>}/>
                        <Route path='flight_data/tsa' element={<TSAPage/>}/>
                        <Route path='flight_data/srs_forward_graphs' element={<SRSForwardGraphsPage/>}/>
                        <Route path='flight_data/orders' element={<FlightDataOrdersPage/>}/>
                        <Route path='flight_data/first_flights' element={<FlightDataFirstFlightsPage/>}/>
                        <Route path='flight_data/fleet_age' element={<FleetAgePage/>}/>

                        <Route path='gitlab/categories' element={<GitlabPlanningPage/>}/>
                        <Route path='gitlab/sales' element={<GitlabSales/>}/>

                        <Route path='exports' element={<ExportsPage/>}/>
                        <Route path='billdotcom/semrush' element={<BillSemrushPage/>}/>
                        <Route path='billdotcom/divvy' element={<DivvyPage/>}/>

                        <Route path='productivity/app_annie' element={<ProductivityAppAnnieSummary/>}/>
                        <Route path='productivity/semrush' element={<ProductivitySemrushPage/>}/>
                        <Route path='productivity/twitter' element={<TwitterPage/>}/>
                        <Route path='productivity/builtwith' element={<BuiltwithPage/>}/>
                        <Route path='productivity/experiments' element={<ExperimentsPage/>}/>
                        <Route path='productivity/hginsights' element={<ProductivityHGInsightsPage/>}/>
                        <Route path='productivity/workforce' element={<ProductivityWorkforcePage/>}/>
                        <Route path='productivity/g2' element={<ProductivityG2Page/>}/>
                        <Route path='productivity/g2_buyer_intent' element={<BuyerIntentPage/>}/>
                        <Route path='productivity/indeed' element={<MondayIndeed/>}/>
                        <Route path='productivity/acuity_linkedin' element={<MondayAcuityLinkedin/>}/>
                        <Route path='productivity/google_trends' element={<MondayGoogleTrends/>}/>


                        <Route path='amzn/ce_sales' element={<AmznCESalesPage/>}/>
                        <Route path='amzn/ce_competitors' element={<CECompetitors/>}/>
                        <Route path='amzn/buy_with_prime' element={<BuyWithPrimePage/>}/>

                        <Route path='datadog/workforce' element={<DatadogWorkforcePage/>}/>
                        <Route path='datadog/hginsights' element={<DatadogHGInsightsPage/>}/>
                        <Route path='datadog/semrush' element={<DatadogSemrush/>}/>
                        <Route path='datadog/g2_buyer_intent' element={<DatadogBuyerIntentPage/>}/>
                        <Route path='datadog/cncf_observability' element={<DatadogCncfGithub/>}/>
                        <Route path='datadog/open_telemetry' element={<DatadogOpenTelemetryGithub/>}/>

                        <Route path='zoominfo/workforce' element={<ZoominfoWorkforcePage/>}/>

                        <Route path='procore/app_annie' element={<ProcoreAppAnniePage/>}/>
                        <Route path='procore/workforce' element={<ProcoreWorkforcePage/>}/>

                        <Route path='autodesk/g2_buyer_intent' element={<AutodeskBuyerIntentPage/>}/>
                        <Route path='autodesk/g2' element={<AutodeskG2Page/>}/>
                        <Route path='autodesk/semrush' element={<AutodeskSemrush/>}/>

                        <Route path='guidewire/workforce' element={<GuidewireWorkforcePage/>}/>

                        <Route path='shopify/builtwith' element={<ShopifyBuiltwithPage/>}/>
                        <Route path='shopify/app_annie' element={<ShopifyAppAnnie/>}/>

                        <Route path='etf/equal_weights' element={<ETFEqualWeightsPage/>}/>
                        <Route path='etf/cagrs' element={<ETFCAGRsPage/>}/>
                        <Route path='etf/spy' element={<EtfSpy/>}/>

                        <Route path='ibkr/app_annie' element={<IBKRAppAnnie/>}/>
                        <Route path='ibkr/app_annie_summary' element={<IBKRAppAnnieSummary/>}/>
                        <Route path='ibkr/semrush' element={<IBKRSemrush/>}/>


                        <Route path='snow/github_stars' element={<GithubStars/>}/>
                        <Route path='snow/db_engines' element={<DBEngines/>}/>
                        <Route path='snow/hginsights' element={<SnowflakeHGInsightsPage/>}/>
                        <Route path='snow/indeed' element={<SnowIndeed/>}/>

                        <Route path='loopnet/sf_offices' element={<SFOfficesTable/>}/>

                        <Route path='text_cruncher/procore' element={<TextCruncherEvaluation/>}/>

                        <Route path='other/indeed' element={<OtherIndeed/>}/>
                        <Route path='other/acuity_linkedin' element={<OtherAcuityLinkedin/>}/>

                        <Route path='mongodb/db_engines' element={<MongoDBEngines/>}/>
                        <Route path='mongodb/github_stars' element={<MongoGithubStars/>}/>
                        <Route path='mongodb/indeed' element={<MongoIndeed/>}/>
                        <Route path='mongodb/google_trends' element={<MongoGoogleTrends/>}/>
                        <Route path='mongodb/stackoverflow' element={<StackOverflowSurveyComponent/>}/>


                        <Route path='dexcom/app_annie' element={<DexcomAppAnnie/>}/>
                        <Route path='dexcom/stelo_app_annie' element={<DexcomSteloAppAnnie/>}/>

                        <Route path='costar/semrush' element={<CostarSemrush/>}/>

                        <Route path='tesla/app_annie' element={<TeslaAppAnnie/>}/>

                        <Route path='llms/semrush' element={<LLMsSemrush/>}/>
                        <Route path='llms/app_annie' element={<LLMsAppAnnie/>}/>

                        <Route path='cpng/semrush' element={<CPNGSemrush/>}/>
                        <Route path='cpng/app_annie' element={<CpngAppAnnie/>}/>

                        <Route path='folio/semrush' element={<FolioSemrush/>}/>
                        <Route path='folio/semrush_folio_guard' element={<FolioGuardSemrush/>}/>
                        <Route path='folio/builtwith' element={<FolioBuiltWith/>}/>
                        {/*<Route path='focus/files' element={<FocusDataList/>}/>*/}
                        {/*<Route path='focus/upload' element={<FocusFileUploadPage/>}/>*/}
                        {/*<Route path='focus/actions/' element={<ActionsViewPage/>}/>*/}
                        {/*<Route path='focus/actions/:id' element={<ActionViewPage/>}/>*/}

                    </Routes>
                </Box>
            </Box>
        </ThemeProvider>
    );
}

export default DashboardContent